import APIService from "../service/APIService";
import auth from "./auth";
import Dashboard from "./pages/Dashboard";
class Accountinfo{

    constructor(){
       this.isSignedUP = true
       this.username = ""
       this.konten = []
       this.kontostaende =[]
       this.anzahlkonten = ""
    }

    setUsername(pusername){
        this.username = pusername
    }

    getUsername(){
        return this.username
    }

    setKontoNr(given){
        this.KontoNr = given
    }

    getKontoNr(){
        return this.KontoNr
    }

    setAnzahlKonten(anzahl){
        this.anzahlkonten = anzahl
    }

    getAnzahlKonten(){
        return this.anzahlkonten
    }
    
    setKonten(konten){
        this.konten = konten
    }

    getKonten(){
        return this.konten
    }

    setKontostand(index, value){
        this.kontostaende[index] = value
    }

    getKontostand(index){
        return this.kontostaende[index]

    }

    extendKonten(konto){
        this.konten.push(konto)
        this.anzahlkonten += 1
    }
    
    async setNewBalance(auth){
        for(var i = 0; i < this.anzahlkonten; i++){
            var ktnr = this.konten[i].kontoNr

            await APIService.getKonto(auth, ktnr).then((data) => {
            this.setKontostand(i,data)     
            })

        }
    }


}

export default new Accountinfo()