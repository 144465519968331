import React from 'react'
import APIService from '../../service/APIService';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import auth from '../auth';
import accountinfo from '../accountinfo';

const Pasttrans = () => {
  const history = useNavigate()
  const [details, setDetails] = useState({kontonummer:"", isloaded: false});

  function abbrechen(){
    history("/Dashboard")
  }

  accountinfo.setNewBalance(auth.getData())

  function load(){

    if(details.isloaded == false){
      setDetails({
        isloaded: true
      })
      APIService.getTransactions(details.kontonummer, auth.getData()).then((data2) => {
      var jsondata2 = JSON.stringify(data2)
    
    
          var table = document.getElementById("transtable")
          var jsonObject = JSON.parse(jsondata2);
          var dataObject = jsonObject.data;
    
          console.log(dataObject)
    
          for(var i = 0; i < Object.keys(dataObject).length; i++){
            var row = table.insertRow(1+i)
            var cellID = row.insertCell(0)
            var celldate = row.insertCell(1)
            var cellsender = row.insertCell(2)
            var cellempfaenger = row.insertCell(3)
            var cellzweck = row.insertCell(4)
            var cellbetrag = row.insertCell(5)
    
            var betragFl = dataObject[i].betrag
            var dateunformatted = dataObject[i].bearbeitungszeitpunkt
    
            var dateformatted = dateunformatted.split("_")
            var datefinal = dateformatted[1].split(".")
    
            cellID.innerHTML = dataObject[i].transaktionsID
            celldate.innerHTML = dateformatted[0] + " | " + datefinal[0]
            cellsender.innerHTML = dataObject[i].senderKontoNr
            cellempfaenger.innerHTML = dataObject[i].empfaengerKontoNr
            cellzweck.innerHTML = dataObject[i].verwendungszweck
            cellbetrag.innerHTML = betragFl.toString() + "€"
          }
          
    
      })
    }

}
/* useEffect(() => {
  load()
}, []); */



  return (
    <div id='styling' className='test'>
      <div>
        <br/>
        <h1>Past transactions</h1>
        <br/>
        <label style={{width: "150px"}}>Kontonummer:</label>
        <input type="text" name='kontonummer' onChange={e => setDetails({...details, kontonummer: e.target.value})} value={details.kontonummer} style={{height: "30px", marginRight: "2vw"}}/>
        <button onClick={load} style={{marginBottom:"5px"}}>Transaktionen laden</button>
        <table id='transtable' name= "transtable">
          <tbody>
          <tr>
              <th>ID</th>
              <th>Datum  </th>
              <th>Sender  </th>
              <th>Empfänger</th>
              <th>Verwendungszweck </th>
              <th>Betrag</th>
          </tr>
          </tbody>

      </table>
        <br/>
        <button onClick={abbrechen} style={{marginLeft: "1vh"}}>Zurück</button>
        </div>
    </div>
  )
}

export default Pasttrans