
import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Dashboard from "./components/pages/Dashboard";
import Navbar from "./components/Navbar";
import Pasttrans from "./components/pages/Pasttrans";
import Transfer from "./components/pages/Transfer";
import Home from "./components/pages/Home";
import { Loginpage } from "./components/pages/Loginpage";
import { ProtectedRoute } from "./components/protectedroute";
import { useState } from "react";
import Signup from "./components/pages/Signup";
import auth from "./components/auth";
import { Logout } from "./components/pages/Logout";
import Infoscreen from "./components/pages/Infoscreen";
import accountinfo from "./components/accountinfo";
import Username from "./components/pages/Username";
import NewAccount from "./components/pages/NewAccount";
import PwaendernKonto from "./components/pages/PwaendernKonto";
import PwaendernKunde from "./components/pages/PwaendernKunde";

function App() {

  const [user, setUsername] = useState({username: ""})

  function changeuser(name){
    setUsername({username: name})
  }



  return (
    <Router>
        <Navbar text={user.username}/>
        <Routes>
            <Route path="/" exact element={<Home/>}/>
            <Route path="/Dashboard" exact element={<ProtectedRoute Component = {Dashboard}/>}/>
            <Route path="/Pasttrans" exact element={<ProtectedRoute Component = {Pasttrans}/>}/>
            <Route path="/Transfer" exact element={<ProtectedRoute Component = {Transfer}/>}/>
            <Route path="/Login" exact element={<Loginpage changeuser={changeuser}/>}/>
            <Route path="/Logout" exact element={<Logout changeuser={changeuser}/>}/>
            <Route path="/Infoscreen" exact  element={<Infoscreen/>}/>
            <Route path="/Username" exact element={<Username/>}/>
            <Route path="/NewAccount" exact element={<ProtectedRoute Component={NewAccount}/>}/>
            <Route path="/PwaendernKonto" exact element={<ProtectedRoute Component={PwaendernKonto}/>}/>
            <Route path="/PwaendernKunde" exact element={<ProtectedRoute Component={PwaendernKunde}/>}/>


            <Route path="/Signup" exact element={<Signup/>}/>
        </Routes>
    </Router>
  );
}


export default App;
