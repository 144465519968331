import React, { useState } from 'react'
import auth from '../auth';
import { Link, useNavigate } from 'react-router-dom';
import App from '../../App';

export function Logout({changeuser}) {

    const [details, setDetails] = useState({email: "", password: ""});
    const history = useNavigate()

    var text 

    const logout = e =>{
        auth.login(details.password, details.email)
        changeuser("")
        history("/")
    }


  return (
    <div id='styling' className='test'>
        <div>
            <h1>Logout:</h1>
            <br/>
            <button onClick={logout} style={{margin: "auto", display: "block", marginTop:"4vh"}}>Logout</button>
        </div>
    </div>
  )
}
