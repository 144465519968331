import axios from 'axios';
import { useState } from 'react';
import { FaRegArrowAltCircleDown } from 'react-icons/fa';
import accountinfo from '../components/accountinfo';
import https from 'https'

//https://dexnet23.ddns.net:80

const getKunde = "https://dexnet23.ddns.net:80/getKunde/";
const signupUser = "https://dexnet23.ddns.net:80/RegistrierungKunde"
const getTransactions = "https://dexnet23.ddns.net:80/getTransaktionen?KontoNr=" 
const creatKonto = " https://dexnet23.ddns.net:80/RegistrierungKonto"
const getKontoByNr = "https://dexnet23.ddns.net:80/getKonto?KontoNr="
const transcationlink = "https://dexnet23.ddns.net:80/user/transaktion"
const pwaendernkonto = "https://dexnet23.ddns.net:80/PasswortAendernKonto"
const pwaendernkunde = "https://dexnet23.ddns.net:80/PasswortAendernKunde"

class APIService {
    


    getKunde(auth){
        
        var ret = axios.get(getKunde, {auth})
        ret.then((data) =>{
            var jsondata = JSON.stringify(data)
            var jsonObject = JSON.parse(jsondata)
            var dataObject = jsonObject.data
            var konten = dataObject.konten
            var name = dataObject.vorname +" "+ dataObject.nachname
            accountinfo.setAnzahlKonten(Object.keys(konten).length)
            accountinfo.setKonten(konten)
            for(var i = 0; i < Object.keys(konten).length; i++){
                accountinfo.setKontostand(i, konten[i].kontostand)
               }
    
            accountinfo.setUsername(name)
        })
        return ret
    }

    getTransactions(ktnr, auth){
        console.log(auth)
        var url = getTransactions + ktnr
        var test = axios.get(url, {auth})
        test.then((data) =>{
            var jsondata = JSON.stringify(data)
            var jsonObject = JSON.parse(jsondata)
            var dataObject = jsonObject.data})
            
        return test
    }


    signupUser(json){
        var res = axios.post(signupUser,json)
        res.then((data) => {
            var jsondata = JSON.stringify(data)
            var jsonObject = JSON.parse(jsondata);
            var dataObject = jsonObject.data
            var ret =dataObject.kundenNr
            accountinfo.setUsername(ret)                    
            return ret
        
        })
    }

    async createKonto(auth, password){
        console.log({auth}, password)
        var res =axios.post(creatKonto, password,{auth})
        res.then((data) => {
            var jsondata = JSON.stringify(data)
            var jsonObject = JSON.parse(jsondata);
            var dataObject = jsonObject.data
            accountinfo.setKontoNr(dataObject.KontoNr)
            accountinfo.extendKonten(dataObject)

            return dataObject
        })
        
    }

   async getKonto(auth, ktnr){
    var returnkontostand
    try {
        var url = getKontoByNr + ktnr
        let ret = await axios.get(url, {auth})
        return ret.data.kontostand

        }
        catch (err) {
            console.error(err);
        }
    }

    transaction(data, auth){

        console.log(data)
        var ret = axios.post(transcationlink, data, {auth})
        ret.then((data) => {
            var jsondata = JSON.stringify(data)
            var jsonObject = JSON.parse(jsondata);
            var dataObject = jsonObject.data
            
            return dataObject
        })

    }

    pwaendernKunde(data, auth){

        var ret = axios.post(pwaendernkunde, data, {auth})
        ret.then((data) => {
            var jsondata = JSON.stringify(data)
            var jsonObject = JSON.parse(jsondata);
            var dataObject = jsonObject.data
            
            return dataObject})

    }

    pwaendernKonto(data, auth){
        var ret = axios.post(pwaendernkonto, data, {auth})
        ret.then((data) => {
            var jsondata = JSON.stringify(data)
            var jsonObject = JSON.parse(jsondata);
            var dataObject = jsonObject.data
            
            return dataObject})

    }
    
}



export default new APIService();