import React from 'react'
import { useNavigate } from 'react-router-dom'
import accountinfo from '../accountinfo'
import auth from '../auth'

function Username() {
    const history = useNavigate()

    function nextpage(){
        history("/Login")
    }

  return (
 <div id='styling' className='test'>
    <div style={{justifyContent: "center"}}>
        <h1>Information:</h1>
        <br/>
        <div>
           Ihr Log-In-Username ist:
           <br/>
           <h2>
           {accountinfo.getUsername()}
           </h2>
           <br/>
           <button onClick={nextpage}>Weiter zum Log-In</button>
            
        </div>
      
    </div>
  </div>
  )
}

export default Username