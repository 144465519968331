import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import APIService from '../../service/APIService';
import accountinfo from '../accountinfo';

const Signup = () => {
  const [details, setDetails] = useState({name: "", lastname: "", birthday: "", password: "", repeatedpassword: "", email: ""});
  const [error, setError] = useState({errormessage: ""})
  const history = useNavigate()

  function setcreds(namegiven,lastnamegiven ,birthdaygiven, passwordgiven, emailgiven){
    var obj = new Object();
    obj.pVorname = namegiven;
    obj.pNachname = lastnamegiven
    obj.pGeburtstag = birthdaygiven;
    obj.pPasswortClrText = passwordgiven;
    obj.pEmail = emailgiven;
    var string = JSON.stringify(obj);
    console.log(JSON.parse(string));
    APIService.signupUser(JSON.parse(string))
    history("/Infoscreen")
  }
    

  const signup = e =>{
    if(details.password == details.repeatedpassword){
      setcreds(details.name, details.lastname, details.birthday, details.password, details.email)
    }
  }



return (
  <div id='styling' className='test'>
      <div style={{alignContent: "flex-start",justifyContent: "center"}}>
          <h1>Sign up:</h1>
          <br/>
          <div style={{marginTop: "50px"}}>
          <div className='signupinner'>
            <label htmlFor="text" style={{fontSize: 20}}>Name:</label>
            <input type="text" name='Name' onChange={e => setDetails({...details, name: e.target.value})} value={details.name} style={{height: "30px"}}/>
            <br/>
          </div>
          <div className='signupinner'>
            <label htmlFor="text" style={{fontSize: 20}}>Last name:</label>
            <input type="text" name='Last name' onChange={e => setDetails({...details, lastname: e.target.value})} value={details.lastname} style={{ height: "30px"}}/>
              <br/>
          </div>
          <div className='signupinner'>
            <label htmlFor="birthday" style={{fontSize: 20}}>Geburtstag:</label>
            <input type="date" name='birthday' onChange={e => setDetails({...details, birthday: e.target.value})} value={details.birthday} style={{height: "30px"}}/>
          </div>
          <div className='signupinner'>
            <label htmlFor="email" style={{fontSize: 20}}>Email:</label>
            <input type="email" name='email' onChange={e => setDetails({...details, email: e.target.value})} value={details.email} style={{height: "30px"}}/>
          </div>
          <div className='signupinner'>
            <label htmlFor="password" style={{fontSize: 20}}>Password:</label>
            <input type="password" name='password' onChange={e => setDetails({...details, password: e.target.value})} value={details.password} style={{height: "30px"}}/>
              <br/>
          </div>
          <div className='signupinner'>
            <label htmlFor="repeatedpassword" style={{fontSize: 20}}>Repeat password:</label>
            <input type="password" name='repeatedpassword' onChange={e => setDetails({...details, repeatedpassword: e.target.value})} value={details.repeatedpassword} style={{height: "30px"}}/>
              <br/>
          </div>
            <button onClick={signup} style={{marginBottom: "20px", marginTop: "30px"}}>Confirm</button>
              <br/>
            <label>{error.errormessage}</label>
            <br/> 
        </div>
        </div>
    </div>
)
}

export default Signup