import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import App from '../../App';
import APIService from '../../service/APIService';
import accountinfo from '../accountinfo';
import auth from '../auth';

 export function Loginpage({changeuser}) {

    const [details, setDetails] = useState({username: "", password: ""});
    const history = useNavigate()

    var text 

    const authorizationdata = {
      username: details.username,
      password: details.password
    }

    function login(){
      APIService.getKunde(authorizationdata).then(() => {
        auth.setData(details.username, details.password)
        auth.login()
        if(auth.isAuthenticated){
          changeuser(details.username)
          history("/Dashboard")
        }
      })

    }


  return (
    <div id='styling' className='test'>
        <div>
            <h1>Login:</h1>
            <br/>
            <div style={{marginTop: "50px"}}>
            <label style={{fontSize: 20}} htmlFor="text">Username:</label>
            <input type="text" name='text' onChange={e => setDetails({...details, username: e.target.value})} value={details.username} style={{height: "30px"}}/>
            </div>
            <br/>
            <div>
            <label htmlFor="password" style={{fontSize: 20}}>Password:</label>
            <input type="password" name='password' onChange={e => setDetails({...details, password: e.target.value})} value={details.password} style={{ height: "30px"}}/>
            </div>
            <br/>
            <button onClick={login} style={{marginBottom: "20px"}}>Confirm</button>
            <br/> 
            <Link to="/Signup" style={{fontWeight: "bold"}}>Haben Sie noch kein Konto? Melden Sie sich jetzt an! </Link>
        </div>
    </div>
  )
}
