import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APIService from '../../service/APIService';
import auth from '../auth';

function PwaendernKunde() {

    const history = useNavigate()

    const [details, setDetails] = useState({passwortalt: "", passwortneu: "", passwortwh: "", message: ""});
    function pwaendern(){

        if(details.passwortneu == details.passwortwh){
            var obj = new Object();
            obj.pNeuesPW = details.passwortneu;
            obj.pAktuellesPW = details.passwortalt;
    
            setDetails({
              message: "Passwort geändert."
            })
            APIService.pwaendernKunde(obj, auth.getData())
        }

    }

    function zurueck(){
        history("/Dashboard")
    }

  return (
    <div  id='styling' className='test'>
    <div>
      <h1>Kontopasswort ändern:</h1>
      <br/>
      <div style={{marginTop: "5vh"}}>
        <label style={{width: "250px"}}>Altes Passwort:</label>
        <input type="password" name='kpww' onChange={e => setDetails({...details, passwortalt: e.target.value})} value={details.passwortalt} style={{height: "30px"}}/>
        <br/>
        <label style={{width: "250px", marginTop: "5px"}}>Neues Passwort:</label>
        <input type="password" name='kpww' onChange={e => setDetails({...details, passwortneu: e.target.value})} value={details.passwortneu} style={{height: "30px", marginTop: "5px"}}/>
        <br/>
        <label style={{width: "250px", marginTop: "5px"}}>Neues Passwort wiederholen:</label>
        <input type="password" name='kpww' onChange={e => setDetails({...details, passwortwh: e.target.value})} value={details.passwortwh} style={{height: "30px", marginTop: "5px"}}/>
        <br/>
        <label>{details.message}</label>
        <div style={{marginTop: "5px"}}>
        <button onClick={pwaendern} style={{marginLeft: "1vh"}}>Ändern</button>
        <button onClick={zurueck} style={{marginLeft: "1vh"}}>Zurück</button>
        </div>
      </div>
      </div>
  </div>
  )
}

export default PwaendernKunde