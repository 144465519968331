import React, { useState } from 'react'
import auth from '../auth'
import { useNavigate } from 'react-router-dom'
import accountinfo from '../accountinfo'
import APIService from '../../service/APIService'
import { useEffect } from 'react'



function Dashboard(){
  const [Info, setInfo] = useState({name: "",kontostand: "", isloaded: false})
  const history = useNavigate()


 function pwaendern(){
  history("/PwaendernKonto")
 }

 function pwaendernKunde(){
  history("/PwaendernKunde")
 }

async function load(){
if(Info.isloaded == false){
  await accountinfo.setNewBalance(auth.getData())
    setInfo({
      name: accountinfo.getUsername(),
      isloaded: true
    })
    var Kontotable = document.getElementById("table")
    for(var i = 0; i < accountinfo.getAnzahlKonten(); i++){
      var Krow = Kontotable.insertRow(1+i)
      var cellKN = Krow.insertCell(0)
      var cellBetrag = Krow.insertCell(1)

      cellKN.innerHTML = accountinfo.getKonten()[i].kontoNr
      cellBetrag.innerHTML = accountinfo.getKontostand(i) + "€"
    }
  }}

  useEffect(() => {
    load()
  }, []);


  function neuesKonto(){
    history("/NewAccount")
  }
  


  return (
    <div  id='styling 'className='test'>
      <div>
        <h1>Dashboard</h1>
        </div>
      <div>
        <br/>

        <h2>Account von {Info.name}:</h2>
        <br/>

        <h3 style={{marginTop: "10px"}}>Guthaben ihrer Konten:</h3>
        <br/>
          <div style={{margin: "0 auto 5px auto"}}>
            <button onClick={neuesKonto}>Neues Konto</button>
          </div>
        <table id="table" name= "table" className='table' >
          <tbody>
          <tr>
              <th>Kontonummer</th>
              <th>Guthaben</th>
          </tr>
          </tbody>

      </table>
      <div>
        <br/>
        <button  style={{marginBottom: "5px"}}onClick={pwaendern}>Kontopasswörter ändern</button>

          <h4 style={{marginTop: "10px"}}>Kunden-Account:</h4>
          <button style={{marginTop: "10px"}} onClick={pwaendernKunde}>Kundenpasswort ändern</button>
          
        </div>
      </div>

      </div>
  )
}

export default Dashboard