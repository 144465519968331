import React from 'react'
import { useNavigate } from 'react-router-dom';
import APIService from '../../service/APIService';
import auth from '../auth';
import { useState } from 'react';
import accountinfo from '../accountinfo';

function NewAccount() {

    const history = useNavigate()
    const [details, setDetails] = useState({kpw: "", kpww: "", message: ""});

    async function erstellen(){
        if(details.kpww == details.kpw){
            await APIService.createKonto(auth.getData(), {pPasswortClrTextKonto: details.kpw})
            setDetails({
                message: 'Erfolgreich. Klicken sie "Zurück" um zurück zum Dashboard zu kommen.'
            })

        }
    }

    function zurueck(){
        history("/Dashboard") 
    }



  return (
    
        <div  id='styling' className='test'>
          <div>
            <h1>Neues Konto erstellen:</h1>
            <br/>
            <div style={{marginTop: "5vh"}}>
              <label>Kontopasswort:</label>
              <input type="password" name='kpw' onChange={e => setDetails({...details, kpw: e.target.value})} value={details.kpw} style={{height: "30px"}}/>
              <br/>
              <label>Kontopasswort wiederholen:</label>
              <input type="password" name='kpww' onChange={e => setDetails({...details, kpww: e.target.value})} value={details.kpww} style={{height: "30px"}}/>
              <br/>
              <label style={{marginTop: "5px", width: "600px", fontWeight: "bold"}}>{details.message}</label>
              <br/>
              <button onClick={erstellen} style={{marginLeft: "1vh", marginTop: "5px"}}>Erstellen</button>
              <button onClick={zurueck} style={{marginLeft: "1vh", marginTop: "5px"}}>Zurück</button>
            </div>
            </div>
        </div>
      )
  
}

export default NewAccount