import React from 'react'

const Home = () => {
  return (
    <div id='styling' className='test'>
      <div>
        <h1 style={{textDecoration: "underline"}}>TGI Bank</h1>
        <br/>

        <div style={{textAlign: "center", fontSize: 25, lineHeight: "50px", marginTop: "50px"}}>
          Die TGI-Bank ist eine als Projekt für den Informatikunterricht an
          <br/> 
          der PMH-Schule Balingen angelegte Simulation eines Banksystems.
          <br/>
          Das "Geld" auf den Konten ist keinesfalls echt,
          <br/>
          wir wollten mit diesem Projekt lediglich unsere eigenen Informatik-Kenntnisse verbessern,
          <br/>
          mehr über die Kommunikation von Frontend und Backend eines serverbasierten System lernen, 
          <br/>
          sowie uns mit den Grundlagen des Account-Handlings vertraut machen.

          <div style={{marginTop: "50px", fontWeight: "bold"}}>
            Teammitglieder: Max, Valentin, Diguang, Julien
          </div>
        </div>
      
      </div>

    </div>
  )
}

export default Home