import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APIService from '../../service/APIService';
import auth from '../auth';

const Transfer = () => {


  const [details, setDetails] = useState({sender: "", empfaenger: "", betrag: "", passwort:"", verwendung: ""});
  const history = useNavigate()

  function send(sender, empfaenger, betrag, pw, verwedung){
    var obj = new Object();
    obj.pKontoNrSender = sender;
    obj.pBetrag = parseInt(betrag);
    obj.pPasswortClrTextKontoSender = pw;
    obj.pKontoNrEmpfaenger = empfaenger;
    obj.pVerwendungszweck = verwedung;
    console.log(obj)
        
    APIService.transaction(obj, auth.getData())
  }

  function set(){
    send(details.sender, details.empfaenger, details.betrag, details.passwort, details.verwendung)
  }

  function abbrechen(){
    history("/Dashboard")
  }

  return (
    <div  id='styling' className='test'>
      <div>
        <h1>Transfer</h1>
        <br/>
        <div style={{marginTop: "5vh"}}>
        <label>Sender:</label>
          <input type="text" name='Sender' onChange={e => setDetails({...details, sender: e.target.value})} value={details.sender} style={{height: "30px"}}/>
          <br/>
          <label>Empfänger:</label>
          <input type="text" name='empfaenger' onChange={e => setDetails({...details, empfaenger: e.target.value})} value={details.empfaenger} style={{height: "30px"}}/>
          <br/>
          <label>Geldbetrag:</label>
          <input type="text" name='betrag' onChange={e => setDetails({...details, betrag: e.target.value})} value={details.betrag} style={{height: "30px"}}/>
          <br/>
          <label>Verwendungszweck:</label>
          <input type="text" name='verwendungszweck' onChange={e => setDetails({...details, verwendung: e.target.value})} value={details.verwendung} style={{height: "30px"}}/>
          <br/>
          <label>Kontopasswort:</label>
          <input type="password" name='password' onChange={e => setDetails({...details, passwort: e.target.value})} value={details.passwort} style={{height: "30px"}}/>
          <br/>
          <button onClick={set}style={{marginTop: "3vh"}}>Senden</button>
          <button onClick={abbrechen} style={{marginLeft: "1vh"}}>Abbrechen</button>
        </div>
        </div>
    </div>
  )
}

export default Transfer