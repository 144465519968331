import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APIService from '../../service/APIService';
import auth from '../auth';

function PwaendernKonto() {

    const history = useNavigate()

    const [details, setDetails] = useState({kontonr: "", kpa: "", kpn: "", kpw: "", message: ""});
    function pwaendern(){

        if(details.kpn == details.kpw){
            var obj = new Object();
            obj.pAktuellesPW = details.kpa;
            obj.pNeuesPW = details.kpn;
            obj.pKontoNr= details.kontonr;

            setDetails({
              message: "Passwort geändert."
            })

            APIService.pwaendernKonto(obj, auth.getData())
        }

    }

    function zurueck(){
        history("/Dashboard")
    }

  return (
    <div  id='styling' className='test'>
    <div>
      <h1>Kontopasswort ändern:</h1>
      <br/>
      <div style={{marginTop: "5vh"}}>
        <label style={{width: "250px"}}>Konto:</label>
        <input type="text" name='kpw' onChange={e => setDetails({...details, kontonr: e.target.value})} value={details.kontonr} style={{height: "30px"}}/>
        <br/>
        <label style={{width: "250px", marginTop: "5px"}}>Altes Kontopasswort:</label>
        <input type="password" name='kpww' onChange={e => setDetails({...details, kpa: e.target.value})} value={details.kpa} style={{height: "30px", marginTop: "5px"}}/>
        <br/>
        <label style={{width: "250px", marginTop: "5px"}}>Neues Kontopasswort:</label>
        <input type="password" name='kpww' onChange={e => setDetails({...details, kpn: e.target.value})} value={details.kpn} style={{height: "30px", marginTop: "5px"}}/>
        <br/>
        <label style={{width: "250px", marginTop: "5px"}}>Neues Kontopasswort wiederholen:</label>
        <input type="password" name='kpww' onChange={e => setDetails({...details, kpw: e.target.value})} value={details.kpw} style={{height: "30px", marginTop: "5px"}}/>
        <br/>
        <label>{details.message}</label>
        <div style={{marginTop: "5px"}}>
        <button onClick={pwaendern} style={{marginLeft: "1vh"}}>Ändern</button>
        <button onClick={zurueck} style={{marginLeft: "1vh"}}>Zurück</button>
        </div>
      </div>
      </div>
  </div>
  )
}

export default PwaendernKonto