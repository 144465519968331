import React from 'react'
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink
  } from './NavbarElements';
  import auth from "../auth"

const Navbar = (props) => {

  return (
    <>
    <Nav>
        <h1 className='navbar'style={{color: '#fff'}}>TGI <br/> Bank</h1>
        <Bars/>
        <NavMenu> 
        <NavLink to="/">
            Home
        </NavLink>
          {props.text != "" &&
            <><NavLink to="/Dashboard">
              Dashboard
            </NavLink><NavLink to="/Pasttrans">
                Past transactions
              </NavLink><NavLink to="/Transfer">
                Transfer
              </NavLink></>          
}
          {(props.text == "")?(            
              <NavLink to="/Login">
                Login
            </NavLink>):
            (            <NavLink to="/Logout">
               Logout
            </NavLink>)
          }

        </NavMenu>
    </Nav>
    </>
  )
}

export default Navbar