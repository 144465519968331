import React from 'react'
import { useNavigate } from 'react-router-dom'
import accountinfo from '../accountinfo'

 export default function Infoscreen() {

  const history = useNavigate()
  function nextpage(){
    if(accountinfo.getUsername() != "")
    {
      history("/Username")
    }
  }

  return (
    <div id='styling' className='test'>
    <div>
        <h1>Information:</h1>
        <br/>
        <div style={{fontSize: "25px"}}>
            Glückwunsch zu ihrer Registrierung!
            <br/> 
            Auf der nächsten Seite wird ihnen ihr künftiger
            <br/>
            Log-In-Username und ihre Kontonummer  mitgeteilt.
            <br/> 
            Bewahren sie diesen gut auf.
            <br/>
        </div>
        <div>
            <button onClick={nextpage} style={{marginTop: "15px"}}>Weiter</button>
            </div>
      
    </div>
  </div>
  )
}

