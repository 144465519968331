import { Navigate } from 'react-router-dom';
import React from 'react';
import auth from './auth';
import { useNavigate } from 'react-router-dom';


export const ProtectedRoute = ({Component}) => {

    if(auth.isAuthenticated()){
        return <Component />
    }
    else
    {
        return <Navigate to= "/Login" />
    }
}