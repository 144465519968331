import React from "react"
import Navbar from "./Navbar"
    class Auth {

        constructor(){
            this.authenticated = false
            this.password = ""
            this.usernamelogin = ""
        
        }
    
        login(){
            this.authenticated = true
        }
    
        setData(username, password){
            this.usernamelogin = username
            this.password = password 
        }

        getData(){
             return {username : this.usernamelogin,
                     password : this.password}
        }
    
        isAuthenticated(){
            return this.authenticated
        }
    }
export default new Auth()